<template>
  <div class="supplier">
    <supplier-menu :active="3" />
    <supplier-filter :gradeActives.sync="gradeActives" @change="filterChange" />
    <supplier-sort :hasPays.sync="hasPays" @change="sortChange" />
    <div class="data-list">
      <div class="content">
        <div class="data-list-main flex_b">
          <div class="data-list-l">
            <div class="list3 hot-servers-list">
              <div class="content">
                <div class="list">
                  <a
                    :href="`https://www.huixianggongye.com/f/shopservie-view-${item.cropCode}-${item.id}.html`"
                    target="_blank"
                    v-for="item in datalist"
                    :key="item.id"
                    class="item"
                  >
                    <el-image
                      :src="item.firstImage"
                      fit="cover"
                      lazy
                    ></el-image>
                    <div class="info">
                      <div class="site">
                        <div class="view">
                          <img src="@/assets/images/public/hot.png" alt="" />
                          {{ item.viewNum || 0 }}
                        </div>
                      </div>
                      <h3>{{ item.name }}</h3>
                      <p>{{ item.remarks || item.name }}</p>
                      <div class="label" v-if="item.yzShopMainNeedTags.length">
                        <span
                          v-for="items in item.yzShopMainNeedTags"
                          :key="items"
                          >{{ companyJson[items] }}</span
                        >
                      </div>
                      <div class="label" v-if="!item.yzShopMainNeedTags.length">
                        <span>{{ item.yzShopCategory.categoryName }}</span>
                        <span>{{ item.yzTaskCategory.name }}</span>
                      </div>
                      <div class="price flex_b flex_align_c">
                        <span>￥{{ item.marketPrice }}</span>
                        <p>了解详情</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="not-data" v-if="!datalist.length && !loading">
                无数据
              </div>
              <div class="loading" v-if="loading">
                <i class="el-icon-loading"></i>数据加载中...
              </div>
              <div class="page">
                <el-pagination
                  background
                  :page-size="pageSize"
                  :current-page.sync="pageNo"
                  @current-change="currentChange"
                  :layout="`prev, pager, next${total > 120 ? ',jumper' : ''}`"
                  prev-text="上一页"
                  next-text="下一页"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
            <hot v-if="!isWap" mode="servers" />
          </div>
          <div class="data-list-r">
            <fast-up />
            <ad />
          </div>
        </div>
      </div>
      <div class="content" v-if="isWap">
        <div class="data-list-main flex_b">
          <div class="data-list-l">
            <hot mode="servers" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import supplierMenu from "@/components/servers/supplier-menu";
import supplierFilter from "@/components/servers/serverss-filter";
import supplierSort from "@/components/servers/serverss-sort";
import hot from "@/components/servers/hot";
import ad from "@/components/servers/ad";
import fastUp from "@/components/servers/fastUp";
export default {
  components: {
    supplierMenu,
    supplierFilter,
    supplierSort,
    hot,
    ad,
    fastUp,
  },
  data() {
    return {
      isWap: false,
      upVisible: false,
      pageNo: 1,
      pageSize: 12,
      total: 0,
      datalist: [],
      companyJson: {},
      loading: false,
      gradeActives: "",
      hasPays: false,
      params: {
        categoryCode: "",
        yzShopActivity: "",
        needTagsActive: "",
      },
      params2: {
        areaCode: "",
        orderBy: "",
        yzTaskTags: "",
        priceGte: "",
        priceLte: "",
      },
      keyword:""
    };
  },
  watch: {
    "$store.state.keyword"(newval, oldval) {
      this.pageNo = 1;
      this.keyword = newval;
      this.getData();
    },
  },
  methods: {
    filterChange(params) {
      this.params = params;
      this.getData();
    },
    sortChange(params) {
      this.params2 = params;
      this.getData();
    },
    currentChange(val) {
      this.pageNo = val;
      this.getData();
    },
    goDetail(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `https://www.huixianggongye.com/f/shop-${item.companyId}.html`;
    },
    goServers(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `https://www.huixianggongye.com/f/shopservie-view-${item.cropCode}-${item.serviceId}.html`;
    },
    goChat(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href =
        "https://www.huixianggongye.com/f/contactKefu?company_id=" + item.companyId;
    },
    getData() {
      this.loading = true;
      this.datalist = [];
      this.$api
        .get("/api/v1/getYzShopMainList", {
          params: {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            name: this.keyword,
            ...this.params,
            ...this.params2,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.data.list) {
            this.datalist = res.data.list.map((item) => {
              item.yzShopMainNeedTags = item.yzShopMainNeedTags
                ? item.yzShopMainNeedTags.split(",")
                : [];
              return item;
            });
          }
          this.total = res.data.count;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.isWap = $(window).width() < 1000;
    let pid = this.$route.query.pid;
    let id = this.$route.query.id;
    if (pid && !id) {
      this.params.categoryCode = pid;
    } else if (pid && id) {
      this.params.categoryCode = id;
    }
    this.$sapi
      .get("/api/v1/getDictDataList-yz_company_auth_items.json")
      .then((res) => {
        res.forEach((item) => {
          this.companyJson[item.dictValue] = item.dictLabel;
        });
        this.keyword = this.$route.query.value;
        this.getData();
      });

    this.$parent.finish ? this.$parent.finish() : null;
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/supplier.scss";
</style>